import { Injectable, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { expiredDate } from '@modules/controls/validators/expired-date.validator';
import { TranslateService } from '@ngx-translate/core';
import { CATEGORY } from '@tokens/category.token';
import { AvailableStrategyField, CreateStrategyRequest, StrategyFor, StrategyItem } from '@type/strategy.type';

@Injectable()
export class StrategyFormService {
  private fb = inject(FormBuilder).nonNullable;

  private translate = inject(TranslateService);

  private category = inject(CATEGORY);

  storeOrClusterList = [
    { name: this.translate.instant('strategy.stores'), id: 'stores' },
    { name: this.translate.instant('strategy.clusters'), id: 'clusters' },
  ];

  strategyBaseOnList = [{ name: this.translate.instant('matrix.matrix'), id: 'matrix' }];

  form = this.fb.group({
    categoryId: this.fb.control(this.category.value()?.id || 0),
    strategyFor: this.fb.control(this.storeOrClusterList[0].id, [Validators.required]),
    name: this.fb.control<string>('', [Validators.required]),
    startDate: this.fb.control<string>('', [expiredDate, Validators.required]),
    strategyBaseOn: this.fb.control(this.strategyBaseOnList[0], [Validators.required]),
    onDate: this.fb.control<string>('', [Validators.required]),
    statuses: this.fb.control<number[]>([], [Validators.required]),
    selectedProps: this.fb.control<AvailableStrategyField[]>(
      [],
      [Validators.required, Validators.minLength(1), Validators.maxLength(5)],
    ),
  });

  convertStrategyItemToFormValue(value: StrategyItem) {
    return {
      categoryId: this.category.value()?.id || 0,
      strategyFor: value.for,
      name: value.name,
      startDate: value.start_date,
      strategyBaseOn: this.strategyBaseOnList.find((el) => el.id === value.based_on) || this.strategyBaseOnList[0],
      onDate: value.on_date,
      statuses: value.strategy_product_statuses.map(({ product_status_id }) => product_status_id),
      selectedProps: value.strategy_fields.map((f) => ({ ...f, id: f.field_id })),
    };
  }

  patchForm(value: Partial<ReturnType<typeof this.form.getRawValue>>) {
    this.form.patchValue(value);
  }

  convertFormValueToRequest(): CreateStrategyRequest {
    const value = this.form.getRawValue();
    return {
      name: value.name,
      based_on: value.strategyBaseOn.id,
      for: value.strategyFor as StrategyFor,
      on_date: value.onDate,
      start_date: value.startDate,
      category_id: value.categoryId,
      strategy_fields_attributes: value.selectedProps.map((prop, order) => ({
        order,
        field_id: prop.id,
      })),
      strategy_product_statuses_attributes: value.statuses.map((id) => ({
        product_status_id: id,
      })),
    };
  }
}
